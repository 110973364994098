(function () {
  'use strict';

  class CommentZoomCtrl {
    constructor($mdDialog, CommentZoom, $mdToast, Discussions, Utils, $rootScope, $stateParams) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.$mdToast = $mdToast;
      vm.Discussions = Discussions;
      vm.Utils = Utils;
      vm.$stateParams = $stateParams;
      vm.currentUser = $rootScope.user;
      vm.obj = CommentZoom.getObj();
      vm.init();
    }
    init() {
      let vm = this,
          obj = {group_id: vm.obj.group_id};
      vm.Discussions.entriesModule(obj)
        .then((data) => {
          vm.discussion = data;
        });
    }
    cancel() {
      let vm = this;
      vm.$mdDialog.cancel();
    }
    hide(data) {
      let vm = this;
      vm.$mdDialog.hide(data);
    }
    message(msg) {
      let vm = this;
      vm.$mdToast.show(
        vm.$mdToast.simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(3000)
      );
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:CommentZoomCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('CommentZoomCtrl', CommentZoomCtrl);
}());
